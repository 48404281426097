"use client";

import { Button } from "@nextui-org/button";
import { FC, useCallback, useState } from "react";
import { MenuScale, Xmark } from "iconoir-react";
import { HEADER_LINKS, SectionId } from "./constants";
import { Link } from "@nextui-org/link";
import { motion } from "framer-motion";
import { ThemeSwitchButton } from "./ThemeSwitchButton";

type Props = {};

export const MobileMenu: FC<Props> = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleIsOpen = useCallback(
		() => setIsOpen((prevValue) => !prevValue),
		[setIsOpen],
	);

	return (
		<>
			<Button
				isIconOnly
				variant="light"
				onPress={toggleIsOpen}
				className="inline-flex justify-self-end lg:hidden"
			>
				{isOpen ? <Xmark /> : <MenuScale className="-scale-x-100" />}
			</Button>

			<motion.nav
				initial={{ height: 0 }}
				animate={{
					height: isOpen ? "auto" : 0,
				}}
				className="col-span-2 overflow-hidden lg:hidden"
			>
				<div className="flex flex-col items-center gap-y-6 py-6">
					{HEADER_LINKS.map(({ text, href }) => (
						<Link
							key={href}
							className="text-sm text-foreground"
							href={href}
							onPress={() => setIsOpen(false)}
						>
							{text}
						</Link>
					))}

					<ThemeSwitchButton />

					<Button
						as={Link}
						href={`#${SectionId.CONTACT}`}
						color="secondary"
						size="sm"
						className="font-semibold"
						fullWidth
					>
						Let{`’`}s talk
					</Button>
				</div>
			</motion.nav>
		</>
	);
};
