import { Link } from "@nextui-org/link";
import { FC } from "react";
import { HEADER_LINKS } from "./constants";

type Props = {};

export const HeaderLinks: FC<Props> = ({}) => {
	return (
		<nav className="flex gap-x-6">
			{HEADER_LINKS.map(({ text, href }) => (
				<Link key={href} className="text-sm text-foreground" href={href}>
					{text}
				</Link>
			))}
		</nav>
	);
};
