"use client";

import { motion, useMotionValue, useScroll, useTransform } from "framer-motion";
import { FC, ReactNode, useCallback, useEffect, useRef } from "react";

const HEADER_HEIGHT = 64;

// The scroll distance at which the header's background and border opacity should be at their maximum
const ANIMATION_COMPLETE_VALUE = 100;

const BORDER_OPACITY_MAX = 0.1;
const BACKGROUND_OPACITY_MAX = 0.02;

type Props = {
	children?: ReactNode;
};

export const HeaderWrapper: FC<Props> = ({ children }) => {
	const elementRef = useRef(null);
	const headerElementHeight = useMotionValue(HEADER_HEIGHT);

	const resizeObserverCallback = useCallback(
		(entries: ResizeObserverEntry[]) =>
			entries.forEach((entry) =>
				headerElementHeight.set(entry.contentRect.height),
			),
		[headerElementHeight],
	);

	useEffect(() => {
		if (elementRef.current) {
			const resizeObserver = new ResizeObserver(resizeObserverCallback);
			resizeObserver.observe(elementRef.current);

			// Cleanup observer on component unmount
			return () => resizeObserver.disconnect();
		}
	}, [elementRef, resizeObserverCallback]);

	const { scrollY } = useScroll();

	const scrollElementHeightCombinedValue = useTransform(
		() => scrollY.get() + headerElementHeight.get() - HEADER_HEIGHT,
	);

	const scrollAnimationPercentage = useTransform(
		scrollElementHeightCombinedValue,
		[0, ANIMATION_COMPLETE_VALUE],
		[0, 1],
		{ clamp: true },
	);

	const borderOpacity = useTransform(
		scrollAnimationPercentage,
		[0, 1],
		[0, BORDER_OPACITY_MAX],
	);
	const backgroundOpacity = useTransform(
		scrollAnimationPercentage,
		[0, 1],
		[0, BACKGROUND_OPACITY_MAX],
	);
	const backdropBlur = useTransform(
		scrollAnimationPercentage,
		[0, 1],
		["blur(0px)", "blur(16px)"],
	);

	return (
		<motion.header
			ref={elementRef}
			style={
				{
					"--tw-border-opacity": borderOpacity,
					"--tw-bg-opacity": backgroundOpacity,
					"--tw-backdrop-blur": backdropBlur,
				} as any
			}
			// This is a bit funky but basically it will switch the header to a grid when in mobile mode so that the mobile menu can easily be rendered below
			className="grid w-full max-w-screen-xl grid-cols-2 items-center justify-between border-b-1 border-foreground bg-foreground px-6 backdrop-blur-lg lg:flex lg:rounded-lg lg:border-1 lg:px-8"
		>
			{children}
		</motion.header>
	);
};
