import { FC } from "react";
import { LogoColor, LogoIcon, LogoIconProps } from "./LogoIcon";
import { Link } from "@nextui-org/link";
import { ClassNameValue, twMerge } from "tailwind-merge";

type Props = {
	color?: LogoColor;
	iconProps?: LogoIconProps;
	className?: ClassNameValue;
};

export const LogoWithText: FC<Props> = ({
	color = "monochrome",
	iconProps,
	className,
}) => {
	return (
		<Link
			className={twMerge(
				["align flex w-fit items-center gap-x-1.5 text-xl text-foreground"],
				className,
			)}
			href="/"
		>
			<LogoIcon color={color} {...iconProps} />

			<h2>next.studio</h2>
		</Link>
	);
};
