"use client";

import { Button } from "@nextui-org/button";
import { FC } from "react";
import { HeaderLinks } from "./DesktopLinks";
import { LogoWithText } from "../LogoWithText";
import { ThemeSwitchButton } from "./ThemeSwitchButton";
import { MobileMenu } from "./MobileMenu";
import { HeaderWrapper } from "./HeaderWrapper";
import { Link } from "@nextui-org/link";
import { config } from "@/config";
import { SectionId } from "./constants";

type Props = {};

export const Header: FC<Props> = ({}) => {
	return (
		<div className="fixed z-50 flex w-full justify-center lg:px-8 lg:pt-8">
			<HeaderWrapper>
				<div className="flex h-[63px] w-36 lg:flex-grow-0">
					<LogoWithText iconProps={{ className: "h-9" }} />
				</div>

				<div className="hidden flex-grow justify-center lg:flex">
					<HeaderLinks />
				</div>

				<div className="hidden w-36 items-center justify-end gap-x-6 lg:flex">
					<ThemeSwitchButton />

					<Button
						as={Link}
						href={`#${SectionId.CONTACT}`}
						color="secondary"
						size="sm"
						className="font-semibold"
					>
						Let’s talk
					</Button>
				</div>

				<MobileMenu />
			</HeaderWrapper>
		</div>
	);
};
