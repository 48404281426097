export enum SectionId {
	SERVICES = "services",
	WHY_US = "why-us",
	TECH_STACK = "tech-stack",
	PROCESS = "process",
	CONTACT = "contact",
}

type LinkDef = {
	href: `#${SectionId}`;
	text: string;
};

export const HEADER_LINKS: LinkDef[] = [
	{
		href: "#services",
		text: "Services",
	},
	{
		href: "#why-us",
		text: "Why Us",
	},
	{
		href: "#tech-stack",
		text: "Tech Stack",
	},
	{
		href: "#process",
		text: "Process",
	},
	{
		href: "#contact",
		text: "Contact",
	},
];
