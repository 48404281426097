"use client";

import { motion } from "framer-motion";
import { SunLight, HalfMoon } from "iconoir-react";
import { useTheme } from "next-themes";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

type Props = {};

export const ThemeSwitchButton: FC<Props> = ({}) => {
	const { theme, setTheme } = useTheme();

	const isDarkMode = useMemo(() => theme === "dark", [theme]);

	const toggleTheme = useCallback(() => {
		setTheme(isDarkMode ? "light" : "dark");
	}, [setTheme, isDarkMode]);

	const [mounted, setMounted] = useState(false);
	// useEffect only runs on the client, so now we can safely show the UI
	useEffect(() => setMounted(true), []);
	if (!mounted) return null;

	const AnimatedSunlight = motion(SunLight);
	const AnimatedHalfMoon = motion(HalfMoon);

	return (
		<button onClick={toggleTheme} className="relative h-6 w-6 cursor-pointer">
			<AnimatedSunlight
				initial={{ opacity: 0 }}
				animate={{ opacity: isDarkMode ? 1 : 0 }}
				className="absolute left-0 top-0"
			/>
			<AnimatedHalfMoon
				initial={{ opacity: 0 }}
				animate={{ opacity: isDarkMode ? 0 : 1 }}
				className="absolute left-0 top-0"
			/>
		</button>
	);
};
