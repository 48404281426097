import { FC } from "react";
import Logo from "@/assets/logo.svg";
import { twMerge, ClassNameValue } from "tailwind-merge";

export type LogoColor = "primary" | "monochrome";

export type LogoIconProps = {
	color?: LogoColor;
	className?: ClassNameValue;
};

export const LogoIcon: FC<LogoIconProps> = ({
	color = "monochrome",
	className,
}) => {
	return (
		<Logo
			className={twMerge(
				color === "monochrome" ? "logo-monochrome" : "logo-primary",
				"h-6",
				className,
			)}
		/>
	);
};
