"use client";

import { NextUIProvider } from "@nextui-org/system";
import { useRouter } from "next/navigation";
import { FC, ReactNode } from "react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { WrapperElementProvider } from "./providers/MainElementProvider";
type Props = { children: ReactNode };

export const Providers: FC<Props> = ({ children }) => {
	const router = useRouter();

	return (
		<NextUIProvider locale="en-US" navigate={router.push}>
			<NextThemesProvider attribute="class" defaultTheme="dark">
				<WrapperElementProvider>{children}</WrapperElementProvider>
			</NextThemesProvider>
		</NextUIProvider>
	);
};
